@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&family=Roboto:wght@300;400;500;700&display=swap');

:root {
  // Primary brand colors
  --primary-color: #6366f1;  // Indigo
  --primary-light: #818cf8;  // Light indigo
  --primary-dark: #4f46e5;   // Dark indigo
  
  // Secondary colors
  --secondary-color: #06b6d4;  // Cyan
  --secondary-light: #22d3ee;  // Light cyan
  --secondary-dark: #0891b2;   // Dark cyan
  
  // Accent colors
  --accent-color: #ec4899;     // Pink
  --accent-light: #f472b6;     // Light pink
  --accent-dark: #db2777;      // Dark pink
  
  // Neutral colors
  --text-light: #f8fafc;       // Slate 50
  --text-dark: #0f172a;        // Slate 900
  --bg-dark: #0f172a;          // Slate 900
  --bg-light: #f8fafc;         // Slate 50
  
  // Card colors
  --card-dark: #1e293b;        // Slate 800
  --card-light: #ffffff;       // White
  
  // Additional UI colors
  --success-color: #10b981;    // Emerald 500
  --warning-color: #f59e0b;    // Amber 500
  --error-color: #ef4444;      // Red 500
  --info-color: #3b82f6;       // Blue 500
  
  // Shadows
  --shadow-dark: rgba(0, 0, 0, 0.25);
  --shadow-light: rgba(0, 0, 0, 0.1);
  
  // Gradients
  --gradient-primary: linear-gradient(135deg, var(--primary-color) 0%, var(--secondary-color) 100%);
  --gradient-accent: linear-gradient(135deg, var(--accent-color) 0%, var(--primary-color) 100%);
  --gradient-cta: linear-gradient(135deg, var(--primary-dark) 0%, var(--primary-color) 50%, var(--primary-light) 100%);
  --gradient-dark: linear-gradient(135deg, var(--bg-dark) 0%, #2a3a58 100%);
  --gradient-light: linear-gradient(135deg, #ffffff 0%, var(--bg-light) 100%);
  
  // Transitions
  --transition-fast: 0.3s ease;
  --transition-normal: 0.5s ease;
  
  // Border radius
  --border-radius-sm: 4px;
  --border-radius-md: 8px;
  --border-radius-lg: 16px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px !important;
  line-height: 1.6;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace !important;
  font-size: 1rem !important;
}

.main-container {
  position: relative;
  min-height: 100vh;
  transition: background-color var(--transition-normal);
  
  &.dark-mode {
    background-color: var(--bg-dark);
    color: var(--text-light);
    position: relative;
    
    &::before {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: 
        radial-gradient(circle at 15% 85%, rgba(99, 102, 241, 0.07) 0%, transparent 25%),
        radial-gradient(circle at 85% 15%, rgba(6, 182, 212, 0.05) 0%, transparent 25%),
        radial-gradient(circle at 50% 50%, rgba(236, 72, 153, 0.03) 0%, transparent 50%);
      pointer-events: none;
      z-index: -1;
    }
    
    // Add subtle noise texture
    &::after {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.65' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E");
      opacity: 0.02;
      pointer-events: none;
      z-index: -1;
    }
  }
  
  &.light-mode {
    background-color: var(--bg-light);
    color: var(--text-dark);
    
    &::before {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: 
        linear-gradient(120deg, rgba(99, 102, 241, 0.03) 0%, transparent 30%),
        linear-gradient(240deg, rgba(6, 182, 212, 0.03) 0%, transparent 30%),
        linear-gradient(0deg, rgba(236, 72, 153, 0.02) 0%, transparent 30%);
      pointer-events: none;
      z-index: -1;
    }
    
    // Add subtle noise texture
    &::after {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.65' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E");
      opacity: 0.02;
      pointer-events: none;
      z-index: -1;
    }
  }
}

.content-wrapper {
  padding-top: 64px; // Navbar height
}

a {
  text-decoration: none;
  color: inherit;
  transition: color var(--transition-fast);
  
  &:hover {
    color: var(--primary-color);
  }
}

/* Typography */
h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 1rem;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

p {
  margin-bottom: 1rem;
}

/* Button Styles */
.cta-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 1.5rem;
  border-radius: var(--border-radius-md);
  font-weight: 600;
  transition: all var(--transition-fast);
  cursor: pointer;
  position: relative;
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    background: var(--gradient-cta);
    z-index: -1;
    opacity: 0;
    transition: opacity 0.3s ease;
    border-radius: var(--border-radius-md);
  }
  
  &.primary {
    background: var(--gradient-cta);
    color: var(--text-light);
    box-shadow: 0 4px 15px rgba(99, 102, 241, 0.3);
    
    &:hover {
      transform: translateY(-3px);
      box-shadow: 0 8px 20px rgba(99, 102, 241, 0.4);
      
      &::before {
        opacity: 1;
        animation: gradientShift 3s infinite;
      }
    }
  }
  
  &.secondary {
    background: transparent;
    border: 2px solid var(--primary-color);
    color: var(--primary-color);
    
    .dark-mode & {
      color: var(--primary-light);
      border-color: var(--primary-light);
    }
    
    &:hover {
      background: rgba(99, 102, 241, 0.1);
      transform: translateY(-3px);
      border-color: var(--primary-light);
    }
  }
}

/* Section Common Styles */
.section-header {
  text-align: center;
  margin-bottom: 3rem;
  
  .section-subtitle {
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: var(--primary-color);
    margin-bottom: 0.5rem;
    display: block;
    
    .dark-mode & {
      color: var(--primary-light);
    }
  }
  
  h1 {
    font-size: 2.5rem;
    position: relative;
    display: inline-block;
    margin-bottom: 1rem;
  }
  
  .underline {
    width: 80px;
    height: 4px;
    background: var(--gradient-primary);
    margin: 0 auto;
    border-radius: var(--border-radius-sm);
  }
}

.container {
  padding: 5rem 0;
  width: 100%;
}

/* Dark Mode Specific Styles */
.dark-mode {
  p, h1, h2, h3, h4, h5, h6, span, label, button {
    color: var(--text-light);
  }
  
  .MuiInputBase-root {
    color: var(--text-light);
  }
  
  .MuiInputLabel-root {
    color: rgba(255, 255, 255, 0.7);
  }
  
  .MuiOutlinedInput-notchedOutline {
    border-color: rgba(255, 255, 255, 0.3);
  }
}

/* Light Mode Specific Styles */
.light-mode {
  p, h1, h2, h3, h4, h5, h6, span, label, button {
    color: var(--text-dark);
  }
  
  .chip {
    background-color: rgba(0, 0, 0, 0.08);
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
}

/* Animations */
@keyframes gradientShift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes pulse {
  0%, 100% {
    opacity: 0.8;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
}

/* Responsive Design */
@media screen and (max-width: 1200px) {
  h1 {
    font-size: 2.2rem;
  }
  
  h2 {
    font-size: 1.8rem;
  }
  
  h3 {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }
  
  h2 {
    font-size: 1.6rem;
  }
  
  h3 {
    font-size: 1.2rem;
  }
  
  .container {
    padding: 3rem 0;
  }
  
  .cta-button {
    padding: 0.6rem 1.2rem;
  }
}