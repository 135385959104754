#contact {
  padding: 6rem 0;
  background-color: rgba(80, 0, 202, 0.03);
  
  .light-mode & {
    background-color: rgba(80, 0, 202, 0.05);
  }
  
  .dark-mode & {
    background-color: rgba(80, 0, 202, 0.07);
  }
  
  .contact-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
  }
  
  .contact-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
    margin-top: 3rem;
  }
  
  .contact-info {
    h2 {
      font-size: 2rem;
      margin-bottom: 1.5rem;
      position: relative;
      padding-bottom: 0.5rem;
      
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 50px;
        height: 3px;
        background: var(--gradient-primary);
        border-radius: 3px;
      }
    }
    
    p {
      margin-bottom: 2rem;
      font-size: 1.1rem;
      line-height: 1.6;
      opacity: 0.9;
    }
    
    .contact-methods {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }
    
    .contact-method {
      display: flex;
      align-items: flex-start;
      gap: 1rem;
      padding: 1rem;
      border-radius: var(--border-radius-md);
      background-color: var(--card-light);
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
      transition: all 0.3s ease;
      
      .dark-mode & {
        background-color: var(--card-dark);
      }
      
      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
      }
      
      .method-icon {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(80, 0, 202, 0.1);
        color: var(--primary-color);
        border-radius: 50%;
        flex-shrink: 0;
      }
      
      .method-details {
        h3 {
          font-size: 1.1rem;
          margin-bottom: 0.3rem;
        }
        
        p {
          font-size: 0.95rem;
          margin-bottom: 0;
          opacity: 0.8;
        }
      }
    }
  }
  
  .contact-form-container {
    .contact-form {
      background-color: var(--card-light);
      padding: 2.5rem;
      border-radius: var(--border-radius-lg);
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
      
      .dark-mode & {
        background-color: var(--card-dark);
        
        .MuiOutlinedInput-notchedOutline {
          border-color: rgba(255, 255, 255, 0.2);
        }
        
        .MuiInputLabel-root {
          color: rgba(255, 255, 255, 0.7);
        }
        
        .MuiOutlinedInput-input {
          color: var(--text-light);
        }
      }
      
      .form-group {
        margin-bottom: 1.5rem;
      }
      
      .form-field {
        width: 100%;
        
        .MuiInputBase-root {
          border-radius: var(--border-radius-md);
        }
        
        .MuiInputLabel-root {
          font-family: 'Poppins', sans-serif;
        }
        
        .MuiOutlinedInput-notchedOutline {
          border-width: 1px;
          transition: all 0.3s ease;
        }
        
        &:hover .MuiOutlinedInput-notchedOutline {
          border-color: var(--primary-color);
        }
      }
      
      .submit-button {
        background: var(--gradient-primary);
        color: white;
        padding: 0.8rem 2rem;
        border-radius: var(--border-radius-md);
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        text-transform: none;
        letter-spacing: 0.5px;
        box-shadow: 0 4px 15px rgba(80, 0, 202, 0.3);
        transition: all 0.3s ease;
        
        &:hover {
          transform: translateY(-3px);
          box-shadow: 0 8px 20px rgba(80, 0, 202, 0.4);
        }
        
        &:disabled {
          background: #cccccc;
          color: #666666;
        }
      }
    }
  }
}

// Responsive styles
@media screen and (max-width: 1024px) {
  #contact {
    .contact-content {
      gap: 2rem;
    }
    
    .contact-form-container {
      .contact-form {
        padding: 2rem;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  #contact {
    padding: 4rem 0;
    
    .contact-content {
      grid-template-columns: 1fr;
    }
    
    .contact-info {
      text-align: center;
      
      h2::after {
        left: 50%;
        transform: translateX(-50%);
      }
    }
    
    .contact-method {
      text-align: left;
    }
  }
}