.cursor-ring,
.cursor-dot {
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  z-index: 9999;
  transition: opacity 0.15s ease-in-out, transform 0.25s ease-in-out;
}

.cursor-ring {
  width: 40px;
  height: 40px;
  border: 2px solid rgba(99, 102, 241, 0.5);
  transition: width 0.3s ease, height 0.3s ease, border 0.3s ease, transform 0.3s ease;
  
  .dark-mode & {
    border-color: rgba(129, 140, 248, 0.5);
  }
  
  &.hidden {
    opacity: 0;
  }
  
  &.clicked {
    transform: translate(-50%, -50%) scale(0.8);
    border-color: var(--accent-color);
  }
  
  &.hovered {
    width: 60px;
    height: 60px;
    border-color: var(--primary-color);
    background-color: rgba(99, 102, 241, 0.05);
    transform: translate(-50%, -50%) scale(1.2);
    
    .dark-mode & {
      border-color: var(--primary-light);
      background-color: rgba(129, 140, 248, 0.05);
    }
  }
}

.cursor-dot {
  width: 8px;
  height: 8px;
  background-color: var(--primary-color);
  transition: transform 0.2s ease, background-color 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  
  .dark-mode & {
    background-color: var(--primary-light);
  }
  
  &.hidden {
    opacity: 0;
  }
  
  &.clicked {
    transform: translate(-50%, -50%) scale(1.5);
    background-color: var(--accent-color);
  }
  
  svg {
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .cursor-ring.hovered + & {
    width: 12px;
    height: 12px;
    background-color: white;
    color: var(--primary-color);
    box-shadow: 0 0 10px rgba(99, 102, 241, 0.5);
    
    svg {
      opacity: 1;
    }
  }
}

.cursor-dot {
  width: 8px;
  height: 8px;
  background-color: var(--primary-color);
  transition: transform 0.2s ease, background-color 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  
  .dark-mode & {
    background-color: var(--primary-light);
  }
  
  &.hidden {
    opacity: 0;
  }
  
  &.clicked {
    transform: translate(-50%, -50%) scale(1.5);
    background-color: var(--accent-color);
  }
  
  svg {
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .cursor-ring.hovered + & {
    width: 12px;
    height: 12px;
    background-color: white;
    color: var(--primary-color);
    box-shadow: 0 0 10px rgba(67, 97, 238, 0.5);
    
    svg {
      opacity: 1;
    }
  }
}

// Hide default cursor when our custom cursor is active
html.has-custom-cursor {
  * {
    cursor: none !important;
  }
}

// Media query for touch devices
@media (pointer: coarse) {
  .cursor-ring,
  .cursor-dot {
    display: none;
  }
}