.hero-section {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 0 2rem;
  
  // Background with subtle radial gradient
  background: radial-gradient(ellipse at center, rgba(15, 23, 42, 0.85) 0%, rgba(15, 23, 42, 0.95) 100%);
  background-size: cover;
  background-position: center;
  
  .light-mode & {
    background: radial-gradient(ellipse at center, rgba(248, 250, 252, 0.85) 0%, rgba(248, 250, 252, 0.95) 100%);
  }
  
  // Dynamic background gradient
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: 
      radial-gradient(circle at 20% 30%, rgba(99, 102, 241, 0.08) 0%, transparent 50%),
      radial-gradient(circle at 80% 70%, rgba(6, 182, 212, 0.08) 0%, transparent 50%),
      radial-gradient(circle at 50% 50%, rgba(236, 72, 153, 0.05) 0%, transparent 50%);
    z-index: 1;
    opacity: 0.8;
    filter: blur(30px);
    animation: gradientMove 20s ease infinite alternate;
  }
  
  // Decorative grid pattern - more subtle
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: 
      linear-gradient(rgba(99, 102, 241, 0.05) 1px, transparent 1px),
      linear-gradient(to right, rgba(99, 102, 241, 0.05) 1px, transparent 1px);
    background-size: 70px 70px;
    opacity: 0.3;
    z-index: 1;
  }
  
  // Particles
  .particles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden;
  }
  
  .particle {
    position: absolute;
    border-radius: 50%;
    background: linear-gradient(135deg, rgba(6, 182, 212, 0.5), rgba(99, 102, 241, 0.5));
    pointer-events: none;
    opacity: 0.3;
    animation: floatParticle linear infinite;
    box-shadow: 0 0 10px rgba(6, 182, 212, 0.3);
    
    .light-mode & {
      opacity: 0.2;
      background: linear-gradient(135deg, rgba(6, 182, 212, 0.3), rgba(99, 102, 241, 0.3));
    }
  }
  
  // Spotlight effect
  .spotlight {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    pointer-events: none;
  }
  
  // Floating elements
  .floating-circle {
    position: absolute;
    border-radius: 50%;
    background: var(--gradient-primary);
    opacity: 0.1;
    filter: blur(20px);
    z-index: 0;
    
    &:nth-child(1) {
      top: 10%;
      right: 10%;
      width: 300px;
      height: 300px;
      animation: floatAnimation 20s infinite alternate ease-in-out;
    }
    
    &:nth-child(2) {
      bottom: 10%;
      left: 10%;
      width: 200px;
      height: 200px;
      animation: floatAnimation 15s infinite alternate-reverse ease-in-out;
    }
    
    &:nth-child(3) {
      top: 50%;
      left: 50%;
      width: 150px;
      height: 150px;
      background: var(--gradient-accent);
      animation: floatAnimation 12s infinite alternate ease-in-out;
    }
  }
}

.hero-content {
  position: relative;
  z-index: 2;
  text-align: center;
  max-width: 900px;
  width: 100%;
  
  .pre-heading {
    display: block;
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
    color: var(--accent-color);
    opacity: 0.9;
  }
  
  .hero-text {
    margin-bottom: 2.5rem;
    
    h1 {
      font-size: 4.5rem;
      font-weight: 800;
      margin-bottom: 1.5rem;
      line-height: 1.1;
      display: flex;
      flex-direction: column;
      align-items: center;
      
      .gradient-text {
        display: block;
        background: var(--gradient-primary);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        position: relative;
        margin-bottom: 0.5rem;
        filter: drop-shadow(0 2px 5px rgba(67, 97, 238, 0.2));
      }
    }
    
    p {
      font-size: 1.3rem;
      max-width: 600px;
      margin: 0 auto;
      opacity: 0.8;
    }
  }
  
  .hero-cta {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    margin-top: 3rem;
    
    .cta-button {
      padding: 1.2rem 2.5rem;
      font-size: 1.1rem;
      border-radius: 50px;
      font-weight: 600;
      letter-spacing: 0.5px;
      transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      
      &.primary {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        background: var(--gradient-cta);
        box-shadow: 0 10px 25px rgba(99, 102, 241, 0.3);
        
        svg {
          transition: transform 0.3s ease;
        }
        
        &:hover {
          transform: translateY(-5px);
          box-shadow: 0 15px 30px rgba(99, 102, 241, 0.4);
          gap: 0.8rem;
          
          svg {
            transform: translateX(3px);
          }
        }
        
        path {
          fill: currentColor;
        }
      }
      
      &.secondary {
        position: relative;
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(10px);
        border: 1px solid rgba(255, 255, 255, 0.2);
        color: var(--text-light);
        overflow: hidden;
        
        .light-mode & {
          background: rgba(15, 23, 42, 0.05);
          color: var(--text-dark);
          border-color: rgba(15, 23, 42, 0.1);
        }
        
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(135deg, 
            rgba(236, 72, 153, 0.2), 
            rgba(99, 102, 241, 0.2)
          );
          opacity: 0;
          transition: opacity 0.4s ease;
        }
        
        &:hover {
          transform: translateY(-5px);
          border-color: var(--accent-color);
          color: var(--accent-light);
          box-shadow: 0 10px 25px rgba(236, 72, 153, 0.2);
          
          &::before {
            opacity: 1;
          }
          
          .light-mode & {
            color: var(--accent-color);
          }
        }
      }
    }
  }
}

// Scroll indicator animation
.scroll-indicator {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  opacity: 0.8;
  animation: fadeInUp 2s infinite;
  
  .mouse {
    width: 26px;
    height: 40px;
    border: 2px solid var(--primary-color);
    border-radius: 20px;
    position: relative;
    margin: 0 auto;
    
    .wheel {
      width: 6px;
      height: 6px;
      background: var(--primary-color);
      border-radius: 50%;
      position: absolute;
      top: 8px;
      left: 50%;
      transform: translateX(-50%);
      animation: mouseWheel 1.5s infinite;
    }
  }
  
  .scroll-arrow {
    display: block;
    margin: 8px auto;
    width: 16px;
    height: 16px;
    
    span {
      display: block;
      width: 8px;
      height: 8px;
      border-right: 2px solid var(--primary-color);
      border-bottom: 2px solid var(--primary-color);
      transform: rotate(45deg);
      margin: -2px 0;
      animation: scrollDown 1.5s infinite;
      
      &:nth-child(2) {
        animation-delay: 0.2s;
        margin-top: -6px;
      }
    }
  }
}

@keyframes floatParticle {
  0% {
    transform: translateY(0) translateX(0);
  }
  100% {
    transform: translateY(-1000px) translateX(100px);
  }
}

@keyframes gradientMove {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 100%;
  }
}

@keyframes mouseWheel {
  0% {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-50%) translateY(10px);
  }
}

@keyframes scrollDown {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-5px, -5px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(5px, 5px);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0.3;
    transform: translate(-50%, 10px);
  }
  50% {
    opacity: 0.8;
    transform: translate(-50%, 0);
  }
  100% {
    opacity: 0.3;
    transform: translate(-50%, 10px);
  }
}

@keyframes gradientShift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes floatAnimation {
  0% {
    transform: translate(0, 0) scale(1);
  }
  25% {
    transform: translate(50px, -30px) scale(1.05);
  }
  50% {
    transform: translate(20px, 50px) scale(0.95);
  }
  75% {
    transform: translate(-30px, 20px) scale(1.05);
  }
  100% {
    transform: translate(0, 0) scale(1);
  }
}

@media screen and (max-width: 1024px) {
  .hero-content {
    .hero-text {
      h1 {
        font-size: 3.5rem;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .hero-content {
    .hero-text {
      h1 {
        font-size: 2.5rem;
      }
      
      p {
        font-size: 1rem;
      }
    }
    
    .hero-cta {
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      
      .cta-button {
        width: 80%;
      }
    }
  }
}
.hero-section {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 0 2rem;
  
  // Linear gradient background with a subtle pattern
  background: linear-gradient(135deg, rgba(15, 23, 42, 0.92) 0%, rgba(15, 23, 42, 0.98) 100%);
  background-size: cover;
  background-position: center;
  
  .light-mode & {
    background: linear-gradient(135deg, rgba(248, 250, 252, 0.92) 0%, rgba(248, 250, 252, 0.98) 100%);
  }
  
  // Animated background elements
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: 
      linear-gradient(217deg, rgba(67, 97, 238, 0.03), rgba(67, 97, 238, 0) 70.71%),
      linear-gradient(127deg, rgba(76, 201, 240, 0.03), rgba(76, 201, 240, 0) 70.71%),
      linear-gradient(336deg, rgba(247, 37, 133, 0.03), rgba(247, 37, 133, 0) 70.71%);
    z-index: 1;
    opacity: 0.6;
    background-size: 200% 200%;
    animation: gradientShift 15s ease infinite;
  }
  
  // Decorative grid pattern
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: 
      linear-gradient(rgba(67, 97, 238, 0.1) 1px, transparent 1px),
      linear-gradient(to right, rgba(67, 97, 238, 0.1) 1px, transparent 1px);
    background-size: 50px 50px;
    opacity: 0.2;
    z-index: 1;
  }
  
  // Floating elements
  .floating-circle {
    position: absolute;
    border-radius: 50%;
    background: var(--gradient-primary);
    opacity: 0.1;
    filter: blur(20px);
    z-index: 0;
    
    &:nth-child(1) {
      top: 10%;
      right: 10%;
      width: 300px;
      height: 300px;
      animation: floatAnimation 20s infinite alternate ease-in-out;
    }
    
    &:nth-child(2) {
      bottom: 10%;
      left: 10%;
      width: 200px;
      height: 200px;
      animation: floatAnimation 15s infinite alternate-reverse ease-in-out;
    }
    
    &:nth-child(3) {
      top: 50%;
      left: 50%;
      width: 150px;
      height: 150px;
      background: var(--gradient-accent);
      animation: floatAnimation 12s infinite alternate ease-in-out;
    }
  }
}

@keyframes gradientShift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes floatAnimation {
  0% {
    transform: translate(0, 0) scale(1);
  }
  25% {
    transform: translate(50px, -30px) scale(1.05);
  }
  50% {
    transform: translate(20px, 50px) scale(0.95);
  }
  75% {
    transform: translate(-30px, 20px) scale(1.05);
  }
  100% {
    transform: translate(0, 0) scale(1);
  }
}

.hero-content {
  position: relative;
  z-index: 2;
  text-align: center;
  max-width: 800px;
  width: 100%;
  
  .hero-text {
    margin-bottom: 2.5rem;
    
    h1 {
      font-size: 3.5rem;
      font-weight: 800;
      margin-bottom: 1.5rem;
      background: var(--gradient-primary);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      line-height: 1.2;
    }
    
    p {
      font-size: 1.2rem;
      max-width: 600px;
      margin: 0 auto;
      opacity: 0.8;
    }
  }
  
  .hero-cta {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 2rem;
    
    .cta-button {
      padding: 1rem 2rem;
      font-size: 1rem;
    }
  }
}

// Scroll indicator animation
.scroll-indicator {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  opacity: 0.8;
  animation: fadeInUp 2s infinite;
  
  .mouse {
    width: 26px;
    height: 40px;
    border: 2px solid var(--primary-color);
    border-radius: 20px;
    position: relative;
    margin: 0 auto;
    
    .wheel {
      width: 6px;
      height: 6px;
      background: var(--primary-color);
      border-radius: 50%;
      position: absolute;
      top: 8px;
      left: 50%;
      transform: translateX(-50%);
      animation: mouseWheel 1.5s infinite;
    }
  }
  
  .scroll-arrow {
    display: block;
    margin: 8px auto;
    width: 16px;
    height: 16px;
    
    span {
      display: block;
      width: 8px;
      height: 8px;
      border-right: 2px solid var(--primary-color);
      border-bottom: 2px solid var(--primary-color);
      transform: rotate(45deg);
      margin: -2px 0;
      animation: scrollDown 1.5s infinite;
      
      &:nth-child(2) {
        animation-delay: 0.2s;
        margin-top: -6px;
      }
    }
  }
}

@keyframes mouseWheel {
  0% {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-50%) translateY(10px);
  }
}

@keyframes scrollDown {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-5px, -5px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(5px, 5px);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0.3;
    transform: translate(-50%, 10px);
  }
  50% {
    opacity: 0.8;
    transform: translate(-50%, 0);
  }
  100% {
    opacity: 0.3;
    transform: translate(-50%, 10px);
  }
}

@media screen and (max-width: 768px) {
  .hero-content {
    .hero-text {
      h1 {
        font-size: 2.5rem;
      }
      
      p {
        font-size: 1rem;
      }
    }
    
    .hero-cta {
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      
      .cta-button {
        width: 80%;
      }
    }
  }
}