.projects-container {
  padding: 6rem 0;
  
  .projects-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
    position: relative;
    
    &::before {
      content: '';
      position: absolute;
      width: 100px;
      height: 100px;
      top: -20px;
      right: 0;
      background: radial-gradient(circle, rgba(67, 97, 238, 0.1) 0%, rgba(0, 0, 0, 0) 70%);
      z-index: -1;
    }
  }
  
  .project-filters {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 3rem;
    
    .filter-btn {
      padding: 0.6rem 1.2rem;
      background: transparent;
      border: none;
      border-radius: 30px;
      font-family: 'Poppins', sans-serif;
      font-size: 0.9rem;
      font-weight: 500;
      color: var(--text-dark);
      cursor: pointer;
      transition: all 0.3s ease;
      position: relative;
      
      .dark-mode & {
        color: var(--text-light);
      }
      
      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 2px;
        background: var(--gradient-primary);
        transition: width 0.3s ease;
        opacity: 0;
      }
      
      &:hover {
        color: var(--primary-color);
        
        &::before {
          width: 30%;
          opacity: 1;
        }
      }
      
      &.active {
        background: linear-gradient(to right, rgba(67, 97, 238, 0.1), rgba(76, 201, 240, 0.1));
        color: var(--primary-color);
        font-weight: 600;
        box-shadow: 0 3px 10px rgba(67, 97, 238, 0.1);
        
        &::before {
          width: 50%;
          opacity: 1;
        }
      }
    }
  }
  
  .projects-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2.5rem;
    margin-top: 1rem;
    perspective: 1000px;
  }
  
  .project-card {
    background-color: var(--card-light);
    border-radius: var(--border-radius-lg);
    overflow: hidden;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
    position: relative;
    display: flex;
    flex-direction: column;
    transform-style: preserve-3d;
    
    .dark-mode & {
      background-color: var(--card-dark);
    }
    
    // Glass effect border
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: var(--border-radius-lg);
      padding: 1px;
      background: linear-gradient(
        to bottom right,
        rgba(255, 255, 255, 0.1),
        rgba(255, 255, 255, 0.05) 25%,
        rgba(0, 0, 0, 0) 50%,
        rgba(0, 0, 0, 0.05) 75%,
        rgba(0, 0, 0, 0.1)
      );
      mask: linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
      -webkit-mask: linear-gradient(#fff 0 0) content-box,
                    linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
      pointer-events: none;
    }
    
    &:hover {
      transform: translateY(-10px) rotateX(2deg) rotateY(2deg);
      box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
      
      .project-image {
        transform: scale(1.05);
      }
      
      .project-overlay {
        opacity: 1;
      }
      
      .project-title {
        color: var(--primary-color);
      }
    }
  }
  
  .project-image-container {
    position: relative;
    width: 100%;
    height: 250px;
    overflow: hidden;
    
    .project-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
      filter: brightness(1);
      
      .dark-mode & {
        filter: brightness(0.9);
      }
    }
    
    .project-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, 
        rgba(67, 97, 238, 0.85), 
        rgba(76, 201, 240, 0.85)
      );
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
      
      &.visible {
        opacity: 1;
      }
      
      .project-links {
        display: flex;
        gap: 1.5rem;
        
        .project-link {
          width: 50px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: rgba(255, 255, 255, 0.9);
          color: var(--primary-color);
          border-radius: 50%;
          transition: all 0.3s ease;
          box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
          
          &:hover {
            background: white;
            transform: scale(1.1);
            box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
          }
          
          svg {
            font-size: 1.3rem;
          }
        }
      }
    }
    
    .featured-badge {
      position: absolute;
      top: 1rem;
      right: 1rem;
      background: linear-gradient(to right, var(--accent-color), var(--accent-light));
      color: white;
      padding: 0.4rem 0.8rem;
      border-radius: 20px;
      font-size: 0.8rem;
      font-weight: 600;
      box-shadow: 0 4px 10px rgba(247, 37, 133, 0.3);
      z-index: 10;
      
      &::before {
        content: '';
        position: absolute;
        top: -2px;
        left: -2px;
        right: -2px;
        bottom: -2px;
        background: linear-gradient(to right, var(--accent-light), var(--accent-color));
        border-radius: 20px;
        z-index: -1;
        opacity: 0;
        transition: opacity 0.3s ease;
      }
      
      &:hover::before {
        opacity: 1;
        animation: pulse 1.5s infinite;
      }
    }
  }
  
  .project-info {
    padding: 1.5rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    
    .project-header {
      display: flex;
      align-items: center;
      gap: 0.8rem;
      margin-bottom: 1rem;
      
      .project-icon {
        color: var(--primary-color);
        font-size: 1.5rem;
      }
    }
    
    .project-title {
      font-size: 1.4rem;
      margin-bottom: 0;
      transition: color 0.3s ease;
    }
    
    .project-description {
      margin: 1rem 0 1.2rem;
      line-height: 1.6;
      opacity: 0.9;
      font-size: 0.95rem;
      flex: 1;
    }
    
    .project-tech {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
      margin-top: auto;
      
      .tech-tag {
        background: linear-gradient(to right, rgba(67, 97, 238, 0.1), rgba(76, 201, 240, 0.1));
        color: var(--primary-color);
        padding: 0.3rem 0.8rem;
        border-radius: 20px;
        font-size: 0.8rem;
        font-weight: 500;
        transition: all 0.3s ease;
        backdrop-filter: blur(5px);
        
        .dark-mode & {
          background: linear-gradient(to right, rgba(67, 97, 238, 0.15), rgba(76, 201, 240, 0.15));
        }
        
        &:hover {
          background: linear-gradient(to right, rgba(67, 97, 238, 0.2), rgba(76, 201, 240, 0.2));
          transform: translateY(-2px);
        }
      }
    }
  }
  
  .no-projects {
    text-align: center;
    padding: 3rem 0;
    font-size: 1.1rem;
    color: var(--text-dark);
    opacity: 0.7;
    
    .dark-mode & {
      color: var(--text-light);
    }
  }
}

@keyframes pulse {
  0%, 100% {
    opacity: 0.8;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
}

// Responsive styles
@media screen and (max-width: 1024px) {
  .projects-container {
    .projects-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media screen and (max-width: 768px) {
  .projects-container {
    padding: 4rem 0;
    
    .project-filters {
      gap: 0.5rem;
      margin-bottom: 2rem;
      
      .filter-btn {
        padding: 0.5rem 1rem;
        font-size: 0.85rem;
      }
    }
    
    .projects-grid {
      grid-template-columns: 1fr;
    }
    
    .project-card {
      &:hover {
        transform: translateY(-5px);
      }
    }
  }
}