#main {
  padding: 6rem 0;
  
  .about-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4rem;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
    position: relative;
    
    // Add a decorative background shape
    &::before {
      content: '';
      position: absolute;
      top: -100px;
      left: -100px;
      width: 200px;
      height: 200px;
      border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
      background: linear-gradient(135deg, rgba(80, 0, 202, 0.1) 0%, rgba(108, 99, 255, 0.1) 100%);
      z-index: -1;
    }
    
    &::after {
      content: '';
      position: absolute;
      bottom: -50px;
      right: -50px;
      width: 150px;
      height: 150px;
      border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
      background: linear-gradient(135deg, rgba(108, 99, 255, 0.1) 0%, rgba(80, 0, 202, 0.1) 100%);
      z-index: -1;
    }
  }
  
  .image-wrapper {
    position: relative;
    width: 350px;
    height: 350px;
    
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: var(--border-radius-lg);
      position: relative;
      z-index: 2;
      box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
    }
    
    // Decorative elements for the image
    .image-blob {
      position: absolute;
      top: -20px;
      left: -20px;
      width: calc(100% + 40px);
      height: calc(100% + 40px);
      background: var(--gradient-primary);
      opacity: 0.3;
      border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
      animation: blobAnimation 8s infinite alternate;
      z-index: 1;
    }
    
    // Remove the image-shape div that was causing the square
  }
  
  .content {
    flex: 1;
    max-width: 600px;
    
    .greeting {
      font-size: 1.5rem;
      font-weight: 500;
      margin-bottom: 0.5rem;
      color: var(--accent-color);
      background: var(--gradient-accent);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    
    h1 {
      font-size: 3rem;
      font-weight: 800;
      margin-bottom: 1rem;
      position: relative;
      display: inline-block;
      
      .accent-dot {
        position: absolute;
        display: inline-block;
        width: 10px;
        height: 10px;
        background: var(--primary-color);
        border-radius: 50%;
        margin-left: 5px;
      }
    }
    
    .title-container {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      margin-bottom: 1.5rem;
      
      .title-icon {
        color: var(--primary-color);
      }
      
      .title {
        font-size: 1.2rem;
        font-weight: 600;
        margin: 0;
      }
    }
    
    .bio {
      font-size: 1.1rem;
      line-height: 1.6;
      margin-bottom: 2rem;
      opacity: 0.9;
    }
    
    .social_icons {
      display: flex;
      gap: 1rem;
      margin-bottom: 2rem;
      
      a {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: rgba(80, 0, 202, 0.1);
        color: var(--primary-color);
        transition: all 0.3s ease;
        
        &:hover {
          background-color: var(--primary-color);
          color: white;
          transform: translateY(-3px);
        }
        
        svg {
          font-size: 1.2rem;
        }
      }
    }
    
    .cta-buttons {
      display: flex;
      gap: 1rem;
      
      .cta-button {
        padding: 0.75rem 1.5rem;
      }
    }
    
    .mobile_social_icons {
      display: none;
    }
  }
}

@keyframes blobAnimation {
  0% {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  }
  25% {
    border-radius: 70% 30% 30% 70% / 70% 70% 30% 30%;
  }
  50% {
    border-radius: 30% 70% 70% 30% / 70% 30% 70% 30%;
  }
  75% {
    border-radius: 70% 30% 30% 70% / 30% 70% 30% 70%;
  }
  100% {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  }
}

// Responsive styles
@media screen and (max-width: 1024px) {
  #main {
    .image-wrapper {
      width: 300px;
      height: 300px;
    }
    
    .content {
      h1 {
        font-size: 2.5rem;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  #main {
    padding: 4rem 0;
    
    .about-section {
      flex-direction: column;
      text-align: center;
      gap: 2rem;
    }
    
    .image-wrapper {
      width: 250px;
      height: 250px;
      margin: 0 auto;
    }
    
    .content {
      .social_icons {
        justify-content: center;
      }
      
      .cta-buttons {
        justify-content: center;
      }
      
      .mobile_social_icons {
        display: none; // We'll hide this since we're showing the social icons above on mobile
      }
    }
  }
}