#expertise {
    background-color: rgba(80, 0, 202, 0.03);
    
    .light-mode & {
      background-color: rgba(80, 0, 202, 0.05);
    }
    
    .dark-mode & {
      background-color: rgba(80, 0, 202, 0.07);
    }
    
    .skills-container {
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 2rem;
    }
    
    .skills-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 2rem;
      margin-top: 2rem;
    }
    
    .skill {
      background-color: var(--card-light);
      padding: 2.5rem 2rem;
      border-radius: var(--border-radius-lg);
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
      transition: all 0.3s ease;
      position: relative;
      overflow: hidden;
      
      .dark-mode & {
        background-color: var(--card-dark);
      }
      
      &:hover {
        transform: translateY(-10px);
        box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
        
        .skill-icon {
          transform: scale(1.1);
        }
      }
      
      // Add decorative background element
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 100px;
        height: 100px;
        background: linear-gradient(135deg, rgba(80, 0, 202, 0.05) 0%, rgba(108, 99, 255, 0.05) 100%);
        border-radius: 0 0 0 100%;
        opacity: 0.5;
        z-index: 0;
      }
      
      .skill-icon {
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1.5rem;
        background: linear-gradient(135deg, rgba(80, 0, 202, 0.1) 0%, rgba(108, 99, 255, 0.1) 100%);
        border-radius: var(--border-radius-md);
        transition: all 0.3s ease;
        
        svg {
          font-size: 1.8rem;
          color: var(--primary-color);
        }
      }
      
      h3 {
        margin-bottom: 1rem;
        font-size: 1.4rem;
        position: relative;
        
        &::after {
          content: '';
          position: absolute;
          bottom: -8px;
          left: 0;
          width: 50px;
          height: 3px;
          background: var(--gradient-primary);
          border-radius: 3px;
        }
      }
      
      p {
        margin-bottom: 1.5rem;
        line-height: 1.6;
        opacity: 0.9;
      }
      
      .flex-chips {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
        
        .chip-title {
          font-weight: 600;
          color: var(--primary-color);
        }
        
        .chips-container {
          display: flex;
          flex-wrap: wrap;
          gap: 0.5rem;
        }
        
        .chip {
          background-color: rgba(80, 0, 202, 0.1);
          color: var(--primary-color);
          font-weight: 500;
          border-radius: 20px;
          padding: 0.3rem 0.8rem;
          transition: all 0.3s ease;
          
          &:hover {
            background-color: var(--primary-color);
            color: white;
            transform: translateY(-2px);
          }
        }
      }
    }
  }
  
  // Responsive styles
  @media screen and (max-width: 1024px) {
    #expertise {
      .skills-grid {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
  
  @media screen and (max-width: 768px) {
    #expertise {
      .skills-grid {
        grid-template-columns: 1fr;
      }
      
      .skill {
        padding: 2rem 1.5rem;
      }
    }
  }