.navbar-fixed-top {
  transition: all 0.3s ease;
  box-shadow: none;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  padding: 0.5rem 0;
  position: relative;
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    top: -100%;
    left: -100%;
    right: -100%;
    bottom: -100%;
    background: radial-gradient(circle at center, rgba(67, 97, 238, 0.1) 0%, rgba(76, 201, 240, 0.05) 25%, rgba(0, 0, 0, 0) 50%);
    opacity: 0;
    transform: scale(0.5);
    filter: blur(40px);
    transition: all 0.8s ease;
    z-index: -1;
    animation: pulseGlow 8s infinite alternate;
  }
  
  &.at-home {
    &::before {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  &.scrolled {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: var(--gradient-primary);
      opacity: 0.3;
    }
  }
  
  &.dark-nav {
    background: rgba(15, 23, 42, 0.8) !important;
    
    &.scrolled {
      background: rgba(15, 23, 42, 0.9) !important;
    }
  }
  
  &.light-nav {
    background: rgba(248, 250, 252, 0.8) !important;
    
    &.scrolled {
      background: rgba(248, 250, 252, 0.9) !important;
    }
  }
}

@keyframes pulseGlow {
  0%, 100% {
    transform: scale(1) rotate(0deg);
  }
  25% {
    transform: scale(1.05) rotate(5deg);
  }
  50% {
    transform: scale(0.95) rotate(-5deg);
  }
  75% {
    transform: scale(1.05) rotate(0deg);
  }
}

.navigation-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  height: 64px;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  
  .logo-container {
    display: flex;
    align-items: center;
    
    .logo {
      font-size: 1.3rem;
      font-weight: 700;
      color: var(--primary-color);
      background: var(--gradient-primary);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  
  .nav-controls {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .nav-items {
    display: flex;
    gap: 0.5rem;
    
    button {
      font-family: 'Poppins', sans-serif;
      font-size: 0.95rem;
      font-weight: 500;
      text-transform: none;
      padding: 0.5rem 0.75rem;
      position: relative;
      transition: all 0.3s ease;
      
      &:hover {
        color: var(--primary-color);
        background: transparent;
      }
      
      &.active-nav-link {
        color: var(--primary-color);
        font-weight: 600;
      }
      
      .active-indicator {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3px;
        background: var(--gradient-primary);
        border-radius: 3px;
      }
    }
  }
  
  .menu-button, .theme-button {
    color: var(--primary-color);
    background-color: rgba(80, 0, 202, 0.1);
    border-radius: 50%;
    padding: 8px;
    
    &:hover {
      background-color: rgba(80, 0, 202, 0.2);
    }
    
    svg {
      font-size: 1.2rem;
    }
  }
  
  .theme-toggle {
    margin-left: 0.5rem;
  }
}

.navigation-bar-responsive {
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  
  &.dark-drawer {
    background-color: var(--bg-dark);
    color: var(--text-light);
    
    .MuiDivider-root {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
  
  &.light-drawer {
    background-color: var(--bg-light);
    color: var(--text-dark);
    
    .MuiDivider-root {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
  
  .mobile-menu-top {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
    
    .logo {
      font-size: 1.8rem;
      font-weight: 700;
      background: var(--gradient-primary);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  
  .MuiListItemButton-root {
    border-radius: var(--border-radius-md);
    margin: 0.3rem 0;
    transition: all 0.3s ease;
    
    &:hover {
      background-color: rgba(80, 0, 202, 0.1);
    }
    
    &.active-section {
      background-color: rgba(80, 0, 202, 0.15);
      
      .MuiListItemText-root {
        color: var(--primary-color);
        font-weight: 600;
      }
    }
  }
  
  .drawer-toggle-theme {
    margin-top: auto;
    display: flex;
    justify-content: center;
    padding: 1rem 0;
    
    .MuiIconButton-root {
      color: var(--primary-color);
      background-color: rgba(80, 0, 202, 0.1);
      
      &:hover {
        background-color: rgba(80, 0, 202, 0.2);
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .navigation-bar {
    padding: 0 1rem;
  }
}