.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    
    &.dark-mode {
      background-color: var(--bg-dark);
      color: var(--text-light);
    }
    
    &.light-mode {
      background-color: var(--bg-light);
      color: var(--text-dark);
    }
  }
  
  .loader-content {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    animation: fadeIn 0.5s ease-in-out;
  }
  
  .loader-logo {
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 2rem;
    background: var(--gradient-primary);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    position: relative;
    
    &::after {
      content: '';
      position: absolute;
      width: 120%;
      height: 120%;
      top: -10%;
      left: -10%;
      background: radial-gradient(circle, rgba(80, 0, 202, 0.2) 0%, rgba(80, 0, 202, 0) 70%);
      z-index: -1;
      border-radius: 50%;
      animation: pulse 2s infinite;
    }
  }
  
  .loader-bar-container {
    width: 200px;
    height: 4px;
    background-color: rgba(80, 0, 202, 0.2);
    border-radius: 2px;
    overflow: hidden;
    margin-bottom: 1rem;
  }
  
  .loader-bar {
    height: 100%;
    background: var(--gradient-primary);
    border-radius: 2px;
    transition: width 0.3s ease-out;
  }
  
  .loader-text {
    font-size: 1rem;
    opacity: 0.8;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 0.6;
    }
    50% {
      transform: scale(1.1);
      opacity: 0.3;
    }
    100% {
      transform: scale(1);
      opacity: 0.6;
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }