footer {
    background-color: var(--card-dark);
    padding: 4rem 0 2rem;
    position: relative;
    
    .light-mode & {
      background-color: var(--card-light);
    }
    
    .footer-content {
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    
    .footer-logo {
      margin-bottom: 2rem;
      
      span {
        font-size: 2rem;
        font-weight: 700;
        background: var(--gradient-primary);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        position: relative;
        
        &::after {
          content: '';
          position: absolute;
          width: 120%;
          height: 120%;
          top: -10%;
          left: -10%;
          background: radial-gradient(circle, rgba(80, 0, 202, 0.2) 0%, rgba(80, 0, 202, 0) 70%);
          z-index: -1;
          border-radius: 50%;
        }
      }
    }
    
    .footer-nav {
      display: flex;
      justify-content: center;
      gap: 2rem;
      margin-bottom: 2rem;
      
      a {
        position: relative;
        font-weight: 500;
        
        &::after {
          content: '';
          position: absolute;
          width: 0;
          height: 2px;
          bottom: -5px;
          left: 50%;
          transform: translateX(-50%);
          background: var(--gradient-primary);
          transition: width 0.3s ease;
          border-radius: 2px;
        }
        
        &:hover::after {
          width: 100%;
        }
      }
    }
    
    .social-links {
      display: flex;
      gap: 1rem;
      margin-bottom: 2rem;
      
      a {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: rgba(80, 0, 202, 0.1);
        color: var(--primary-color);
        transition: all 0.3s ease;
        
        &:hover {
          background-color: var(--primary-color);
          color: white;
          transform: translateY(-3px);
        }
        
        svg {
          font-size: 1.2rem;
        }
      }
    }
    
    .copyright {
      p {
        font-size: 0.9rem;
        opacity: 0.7;
        margin-bottom: 0;
      }
    }
    
    .scroll-to-top {
      position: absolute;
      bottom: 4rem;
      right: 2rem;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--gradient-primary);
      color: white;
      border-radius: 50%;
      box-shadow: 0 4px 15px rgba(80, 0, 202, 0.3);
      cursor: pointer;
      transition: all 0.3s ease;
      
      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 8px 20px rgba(80, 0, 202, 0.4);
      }
      
      svg {
        font-size: 1.5rem;
      }
    }
  }
  
  // Responsive styles
  @media screen and (max-width: 768px) {
    footer {
      padding: 3rem 0 5rem;
      
      .footer-nav {
        flex-wrap: wrap;
        gap: 1rem 2rem;
      }
      
      .scroll-to-top {
        right: 50%;
        transform: translateX(50%);
        bottom: 2rem;
        
        &:hover {
          transform: translateX(50%) translateY(-5px);
        }
      }
    }
  }